<template>
  <svg
    width="238"
    height="229"
    viewBox="0 0 238 229"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="118.899" cy="114.5" rx="118.5" ry="114.5" fill="#F7FBFD" />
    <rect
      x="61.8992"
      y="40.5"
      width="114.355"
      height="148"
      rx="3.5"
      stroke="#001965"
    />
  </svg>
</template>

<script>
export default {
  name: 'ImgBlankFormLarge',
}
</script>

<style scoped></style>
